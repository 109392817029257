import React, {useState, Fragment} from 'react';
import { useParams } from 'react-router-dom';
import Slider from "react-slick";

import Footer from '../../components/global/Footer';
import PageTitle from '../../components/global/PageTitle';
import Header from '../../components/header/Header';
import QuickView from '../../components/products/QuickView';

/**
 * lookbook data
 */

import data from '../../data/lookbooks-ss25.json';

/**
 * single lookbook page with  Slider Images
 * @param options
 * @returns {*}
 * @constructor
 * */
function LookbookSliderImages({options}) {

  /**
   * states
   */
  const [showQuickView, setShowQuickView] = useState(false);
  const [quickViewData, setQuickViewData] = useState({});

  /**
   * Handel Quick View Close
   */
  const HandelQuickViewClose = (e) => {
      e.preventDefault();
      setShowQuickView(false);
      setQuickViewData({});
  };

  /**
   * slider settings
   */
  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplaySpeed: 2000,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
  };

  const { id } = useParams();

  // Find the lookbook data by ID
  const lookbookData = data.filter(item => item.id === parseInt(id))[0];


  const { images, title, content, outfit } = lookbookData;

  return (
    <Fragment>
        {showQuickView
            ? <QuickView
                data={quickViewData}
                onQuickViewCloseClick={HandelQuickViewClose}
            />
            : ''
        }

        <Header options={options}/>

        <PageTitle name="Lookbook single"/>

        {/* start shop-single-section */}
        <section className="shop-single-section section-padding">
            <div className="container-1410">
                <div className="row">
                    <div className="col col-md-6">
                        <div className="shop-single-slider slider-thumbnail">
                            <Slider {...settings}>
                                {
                                    images.map((item, i) => (
                                        <div key={i} className="item">
                                            <img src={process.env.PUBLIC_URL + "/" + item} alt=""/>
                                        </div>
                                    ))
                                }
                            </Slider>
                            <div className="slider-nav"></div>
                        </div>
                    </div>
                    <div className="col col-md-6">
                        <div className="product-details">
                            <h2>{title}</h2>
                            <p>{content}</p>
                        </div>
                        <div className="lookbook-info">
                            <div className="">
                                <h2>Outfit</h2>
                                <ul>
                                    {
                                        outfit.map((item, i) => (
                                            <p key={i}>
                                              <span className="item"> {item} </span>
                                            </p>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* end col */}
                </div>
                {/* end row */}
            </div>
            {/* end of container */}
        </section>
        {/* end of shop-single-section */}

        <Footer/>
    </Fragment>
);
}

export default LookbookSliderImages;
