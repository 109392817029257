import React, {Fragment} from 'react';
import NewsletterWidget from "../widget/NewsletterWidget";
import ContactWidget from "../widget/ContactWidget";
import CompanyWidget from "../widget/CompanyWidget";
import PaymentWidget from "../widget/PaymentWidget";
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

/**
 * footer component
 * @returns {*}
 * @constructor
 */
function Footer() {

    return (
        <Fragment>
            {/* start site-footer */}
            <footer className="site-footer">
                <div className="container-1410">
                    <div className="row widget-area">
                        <div className="col-lg-5 col-xs-6  widget-col about-widget-col">
                            <NewsletterWidget/>
                        </div>
                        <div className="col-lg-4 col-xs-6 widget-col">
                            <ContactWidget/>
                        </div>
                        <div className="col-lg-3 col-xs-6 widget-col">
                            <CompanyWidget/>
                        </div>
                        {/* <div className="col-lg-2 col-xs-6 widget-col">
                            <PaymentWidget/>
                        </div> */}
                    </div>
                </div>
                {/* end container */}
                <div className="lower-footer">
                    <div className="container-1410">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="lower-footer-inner clearfix">
                                    <div>
                                        <p>Copyright © {new Date().getFullYear()} Spaceport</p>
                                    </div>
                                    <div className="social">
                                        <ul className="clearfix">
                                            <li>
                                                <NavLink
                                                    to={""}
                                                    onClick={() => window.open("https://facebook.com/candeclothingg/", "_blank")}
                                                >
                                                    <FontAwesomeIcon icon={faFacebookF} />
                                                </NavLink>                                                    
                                            </li>
                                            <li>
                                                <NavLink 
                                                    to={""}
                                                    onClick={() => window.open("https://instagram.com/_candeclothing_/", "_blank")}
                                                >
                                                    <FontAwesomeIcon icon={faInstagram} />
                                                </NavLink>  
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="extra-link">
                                        <ul>
                                            <li>
                                                <NavLink to={"/cookies"}>
                                                    <span>{"Cookies"}</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/privacy"}>
                                                    <span>{"Privacy"}</span>
                                                </NavLink>
                                            </li>
                                            <li>    
                                                <NavLink to={"/terms"}>
                                                    <span>{"Terms"}</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* end site-footer */}
        </Fragment>
    );
}


export default Footer;
