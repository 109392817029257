import React, {Fragment} from 'react';
import featuredCollectionsData from '../../data/featured-collections.json';
import {Link} from "react-router-dom";

/**
 * Featured Collections Component
 * @returns {*}
 * @constructor
*/

function FeaturedCollections() {
  return (
      <Fragment>
          {/* start featured-proeducts-section-s2 */}
          <section className="featured-proeducts-section-s2 section-padding">
              <div className="container-1410">
                  <div className="row">
                      <div className="col col-xs-12">
                          <div className="product-grids clearfix">
                              {
                                  featuredCollectionsData.map((collection, index) => (
                                      <div key={index} className={"grid " + (collection.disabled === true ? "disabled" : "")}>
                                          <div className="img-holder">
                                              <img loading="lazy" src={process.env.PUBLIC_URL + collection.img} alt=""/>
                                          </div>
                                          <div className="details">
                                            <h3>{collection.title}</h3>
                                            <Link className={"shop-btn " + (collection.disabled === true ? "btn-disabled" : "")} to={collection.link}>
                                              Discover More
                                            </Link>
                                          </div>
                                      </div>
                                  ))
                              }
                          </div>
                      </div>
                  </div>
              </div>
              {/* end container-1410 */}
          </section>
          {/* end featured-proeducts-section-s2 */}
      </Fragment>
  );
}


export default FeaturedCollections;
